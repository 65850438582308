import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

const Reveal = () => {
  // Helmet will add the class server side
  // once component is mounted 
  const hiddenClass = "hidden"
  const [bodyClass, setBodyClass] = useState(hiddenClass)

  useEffect(() => {
    setBodyClass("")
  }, [])

  return (
    <Helmet
      bodyAttributes={{
        class: hiddenClass,
      }}
      style={[
        { rel: `text/css`, cssText: `.${bodyClass} { display: none; }` },
      ]}
    />
  )
}

export default Reveal
