import React from "react"
import { Helmet } from 'gatsby-plugin-react-i18next';

function SEO({ title, description, language}) {
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
      ]}
      script={[
        {
          type: "text/javascript",
          innerHTML: `window.c2foData = {
          "digitalData": {
              "site": "c2focom",
              "section": "lp",
              "subSection":"lp",
              "pageTitle":"${title}",
              "language": "${language}", 
          }
      };`,
        },
      ]}
    >
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP" crossorigin="anonymous" />
    </Helmet>
  )
}

export default SEO
