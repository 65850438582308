import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import languageSelectStyles from "./language.module.css"

export const LanguageSelect = () => {
  const { language, changeLanguage } = useI18next()
  const selectLanguageValues = {
    "en": "English",
    "pt": "Português",
    "es": "Español",
    "fr": "Français",
    "nl": "Nederlands",
    "de": "Deutsch",
    "it": "Italiano",
    "tr": "Türkçe",
    "ru": "Русский",
    "zh-CN": "简体中文",
    "zh-TW": "繁體中文",
  }

  return (
    <div className={languageSelectStyles.c2foSelect__arrow}>
      <select
        className={languageSelectStyles.c2foLanguage__select}
        value={language}
        onChange={e => {
          // remove lang query param to avoid redirect from language change
          changeLanguage(e.target.value)
        }}
      >
        {Object.keys(selectLanguageValues).map(lang => (
          <option value={lang} key={lang}>
            {selectLanguageValues[lang]}
          </option>
        ))}
      </select>
    </div>
  )
}
